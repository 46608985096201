import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hautausmaa from "../images/hautausmaa.jpg"
const JäseneksiHakeutuminen = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Jäseneksi hakeutuminen" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Jäseneksi hakeutuminen</h1>
        </div>
      </div>
    </div>
    <div className="spacer-60" />
    <div className="col-md-8 col-md-offset-2">
      <img className="pull-right padding-l25 w-6/12" src={hautausmaa} alt="" />

      <p>
        Täytä ja tulosta jäsenhakemuslomake ja palauta se seuraavaan
        osoitteeseen:
      </p>
      <p>Pääsihteeri Matti Halme</p>
      <p>
        Koivurinne 4<br />
        18100 HEINOLA
        <br />
        Puh. 0400 618872
        <br />
        Sähköposti: info(at)shk.fi
      </p>

      <a className="button button-primary" href="/doc/jasenlomake.pdf">
        Lataa lomake
      </a>
    </div>
  </Layout>
)

export default JäseneksiHakeutuminen
